import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "3-create-the-callback-page"
    }}>{`3. Create the callback page`}</h2>
    <p>{`Auth0 and most other authentication providers use OAuth. That requires redirecting your user to their login form. After login, the provider redirects the user back to your app.`}</p>
    <p>{`Any way of creating React pages should work, here's what I use for Gatsby:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React, { useEffect } from "react"
import { useAuth } from "react-use-auth"
import Layout from "../components/layout"

const Auth0CallbackPage = () = {
    const { handleAuthentication } = useAuth()
    useEffect(() => {
        handleAuthentication()
    }, [])

    return (
        <Layout>
          <h1>
            This is the auth callback page, 
            you should be redirected immediately!
          </h1>
        </Layout>
    )
}

export default Auth0CallbackPage
`}</code></pre>
    <p>{`The goal is to load a page, briefly show some text, and run the `}<inlineCode parentName="p">{`handleAuthentication`}</inlineCode>{` method from useAuthon page load.`}</p>
    <p>{`That method will create a cookie in local storage with your user's information and redirect back to homepage. Redirecting to other post-login pages currently isn't supported but is a good idea now that I thought of it 🤔`}</p>
    <p>{`PS: Make sure you add `}<inlineCode parentName="p">{`domain/auth0_callback`}</inlineCode>{` as a valid callback URL in your Auth0 config`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      